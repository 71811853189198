import React from 'react'
import config from '../config'
import AppStoreButton from './components/AppStoreButton'
import GooglePlayButton from './components/GooglePlayButton'
import './AppDownloadInfo.less'

function AppDownloadInfo() {
  return (
    <div className="store-container">
      <div className={'app-info-container'}>
        <div className="logo-container">
          <img src="https://reaction-storage-dev.s3.eu-west-3.amazonaws.com/invites/bird.png" alt="" className="logo" />
        </div>

        <div>
          <GooglePlayButton url={config.googlePlayUrl} />

          <AppStoreButton url={config.appStoreUrl} />
        </div>
      </div>
    </div>
  )
}

export default AppDownloadInfo
