import React from 'react'
import appStoreIcon from '../../assets/appstore.png'

interface Props {
  url: string
}

function AppStoreButton({ url }: Props) {
  return (
    <a href={url} target="_blank" className="apple-link" rel="noreferrer">
      <img src={appStoreIcon} alt="Reaction Club on AppStore" className="store-picture" />
    </a>
  )
}

export default AppStoreButton
