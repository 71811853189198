const version = process.env.VERSION
const app = process.env.APP

console.log(`
********************************************
******  APP: ${app} (${version})
********************************************
`)

const config = {
  env: process.env.APP_ENV,
  serverUrl: process.env.API_URL as string,

  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.reactionclub.employee',
  appStoreUrl: 'https://apps.apple.com/us/app/reaction-club/id1445549712',

  bugsnag_api_key: 'd5e0e26932db8b26dd0dffc6ca7e4154',

  default: {
    app_link: 'https://start.reaction-club.app/open',
  },
}

console.log('config', config)

export default config
