import _ from 'lodash'
import QRCode from 'qrcode.react'
import React from 'react'
import Loader from 'react-loader-spinner'
import api from '../api/api'
import CompanyInviteInfo from './components/CompanyInviteInfo'
import PlaybookInviteInfo from './components/PlaybookInviteInfo'

interface Props {
  invite: any
}
class InviteInfo extends React.PureComponent<Props> {
  render() {
    const { invite } = this.props
    const { link, action } = invite

    if (action === 'playbook_instance_invite') {
      console.log('playbook invvite')
      return <PlaybookInviteInfo invite={invite} />
    }

    if (action === 'company_invite') {
      return <CompanyInviteInfo invite={invite} />
    }

    return null
  }
}

export default InviteInfo
