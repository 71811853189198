import React from 'react'
import googleIcon from '../../assets/google.png'

interface Props {
  url: string
}

function GooglePlayButton({ url }: Props) {
  return (
    <a href={url} target="_blank" className="google-link" rel="noreferrer">
      <img src={googleIcon} alt="Reaction Club on Google Play" className="store-picture" />
    </a>
  )
}

export default GooglePlayButton
