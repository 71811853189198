import React, { useMemo } from 'react'
import { validate } from 'uuid'
import InviteDefault from './InviteDefault'
import InviteRemote from './InviteRemote'

import './invite.less'

interface Props {}
function Invite(props: Props) {
  const { pathname = '' } = window.location

  const { id, isDefaultLink } = useMemo(() => {
    const pathSplit = pathname.split('/')
    const id = pathSplit[pathSplit.length - 1]
    const isDefault = !id || !validate(id)

    return { id, isDefaultLink: isDefault }
  }, [pathname])

  if (isDefaultLink) return <InviteDefault />

  return <InviteRemote id={id} />
}

export default Invite
