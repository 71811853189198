/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Link } from '@reaction-club-types/core'
import QRCode from 'qrcode.react'
import React from 'react'
import { useAsyncFetch } from 'react-hooks-async-handlers'
import { Oval } from 'react-loader-spinner'
import { RendererStatusSplit } from 'react-renderer-status-split'
import api from '../api/api'
import InviteInfo from './InviteInfo'

interface Props {
  id: string
}
function InviteRemote(props: Props) {
  const { id } = props

  const actionFetch = useAsyncFetch(async () => {
    const data: Link = await api.getInviteInfo({ id })
    return data
  }, [id])

  return (
    <div
      css={css`
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      `}
    >
      <RendererStatusSplit
        statuses={actionFetch}
        renderLoading={() => (
          <div
            css={css`
              margin-bottom: 20px;
            `}
          >
            <Oval color="#00BFFF" height={40} width={40} />
          </div>
        )}
        renderEmpty={() => (
          <div
            css={css`
              margin-bottom: 20px;
            `}
          >
            link is not valid
          </div>
        )}
        render={(invite) => (
          <div className="invite-container">
            <h1 className="invite__title">You are invited!</h1>
            <InviteInfo invite={invite} />
            <p className="invite__barcode-description">Scan the barcode to download the Reaction Club and join!</p>
            <QRCode value={invite.link} size={250} />
          </div>
        )}
      />
    </div>
  )
}

export default InviteRemote
