import QRCode from 'qrcode.react'
import React from 'react'
import config from '../config'

interface Props {}
function InviteDefault(props: Props) {
  const link = config.default.app_link

  return (
    <div className="invite-container">
      {/* <h1 className="invite__title">You are invited!</h1> */}
      <p className="invite__barcode-description">Scan the barcode to download the Reaction Club and join!</p>
      <QRCode value={link} size={250} />
    </div>
  )
}

export default InviteDefault
