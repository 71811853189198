import React from 'react'

interface Props {
  invite: any
}
function CompanyInviteInfo(props: Props) {
  const { invite } = props
  const { company } = invite

  const { id, logo, name } = company

  return (
    <div className="invite-group-container">
      <div className={'invite__picture-container'}>
        <img src={logo} alt={'company logo'} className={'invite__company-picture'} />
      </div>

      <div>
        <span className={'invite-group-label'}>Company Invite</span>
        <div>
          <span className={'invite-group-name'}>{name}</span>
        </div>
      </div>
    </div>
  )
}

export default CompanyInviteInfo
