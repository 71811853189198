import React from 'react'

interface Props {
  invite: any
}
function PlaybookInviteInfo(props: Props) {
  const { invite } = props
  const { link, playbookInstance } = invite

  const { id, asset, name } = playbookInstance

  const picture = asset?.url

  return (
    <div className="invite-group-container">
      <div className={'invite__picture-container'}>
        <img src={picture} alt={'group-picture'} className={'invite-group-picture'} />
      </div>

      <div>
        <span className={'invite-group-label'}>Group Invite</span>
        <div>
          <span className={'invite-group-name'}>{name}</span>
        </div>
      </div>
    </div>
  )
}

export default PlaybookInviteInfo
