import React from 'react'
import { BugsnagErrorBoundary } from './libs/bugSnag'
import AppDownloadInfo from './app/AppDownloadInfo'
import Invite from './invite/Invite'

export default () => (
  <BugsnagErrorBoundary>
    <div className="root-invite-container">
      <Invite />

      {/* <div> */}
      {/*  <h1 className="title">Download Reaction Club</h1> */}
      {/*  <p className="description">Once you have the App click on the link again from your mobile phone</p> */}
      {/* </div> */}

      <AppDownloadInfo />
    </div>
  </BugsnagErrorBoundary>
)
